import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Headline2, Headline3, Headline4, Larger, Spacing, Title4, useLayoutIsMobile } from '@pfct/purple';
import React from 'react';
const IMAGE_MAIN_PATH = `${process.env.NEXT_PUBLIC_STATIC_URL}/assets/index/friends`;
const FRIENDS_IMAGE_URL_LIST: {
  url: string;
  title: string;
  subTitle: string;
}[] = [{
  url: 'logo_minister.png',
  title: '2019 국무총리 표창',
  subTitle: "'제 4회 금융의 날' 금융혁신 부문"
}, {
  url: 'logo_clsa.png',
  title: 'CLSA Capital Partners',
  subTitle: '전략적 파트너십 체결'
}, {
  url: 'logo_bloomberg.png',
  title: '블룸버그',
  subTitle: '한국 대표 P2P 금융회사로 조명'
}, {
  url: 'logo_cnbc.png',
  title: 'CNBC',
  subTitle: '크플 리스크 관리 전략 소개'
}, {
  url: 'logo_iflr.png',
  title: 'IFLR APAC AWARDS',
  subTitle: "한국 핀테크 기업 최초 '올해의 딜' 선정"
}];
const Styled = {
  Wrapper: styled.section`
		display: flex;
		background: #fff;
		padding: 0 20px;

		${Larger(css`
			padding: 0;
			justify-content: center;
		`)}
	`,
  ContentWrapper: styled.section`
		padding: 50px 0;
		width: 100%;

		${Larger(css`
			width: 1080px;
			padding: 50px 0;
		`)}
	`,
  ItemList: styled.ul`
		display: grid;
		flex-direction: column;
		grid-template-columns: repeat(2, 1fr);
		gap: 50px;

		margin: 0;
		padding: 0;

		${Larger(css`
			grid-template-columns: repeat(3, 1fr);
			gap: 50px;
		`)}
	`,
  ItemWrapper: styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
  ItemImageWrapper: styled.div`
		display: flex;
		align-items: center;
		justify-content: center;

		width: 80px;
		height: 35px;

		${Larger(css`
			width: 120px;
			height: 45px;
		`)}
	`,
  ItemImage: styled.img`
		display: flex;
		width: 40px;
		margin: 0;
		padding: 0;

		${Larger(css`
			width: 60px;
		`)}
	`
};
function Friends() {
  const isMobile = useLayoutIsMobile();
  const Text = isMobile ? Headline3 : Headline2;
  return <Styled.Wrapper data-sentry-element="unknown" data-sentry-component="Friends" data-sentry-source-file="Friends.tsx">
			<Styled.ContentWrapper data-sentry-element="unknown" data-sentry-source-file="Friends.tsx">
				<div>
					<Text data-sentry-element="Text" data-sentry-source-file="Friends.tsx">대한민국을 대표하는</Text>
					<Text data-sentry-element="Text" data-sentry-source-file="Friends.tsx">크플</Text>
				</div>
				<Spacing spaceNumber={50} data-sentry-element="Spacing" data-sentry-source-file="Friends.tsx" />
				<Styled.ItemList data-sentry-element="unknown" data-sentry-source-file="Friends.tsx">
					{FRIENDS_IMAGE_URL_LIST.map(item => <Styled.ItemWrapper key={item.url}>
							<Styled.ItemImageWrapper>
								<Styled.ItemImage src={`${IMAGE_MAIN_PATH}/${item.url}`}></Styled.ItemImage>
							</Styled.ItemImageWrapper>
							<Spacing spaceNumber={10} />
							<Headline4 textAlign="center">{item.title}</Headline4>
							<Spacing spaceNumber={5.6} />
							<Title4 textAlign="center">{item.subTitle}</Title4>
						</Styled.ItemWrapper>)}
				</Styled.ItemList>
			</Styled.ContentWrapper>
		</Styled.Wrapper>;
}
export default Friends;