import React, { useContext, useEffect } from 'react';
import * as Styled from './index.styled';
import { CsPhoneNumber, PfCsEmailAddress } from '@peoplefund/constants/company';
import { GlobalLayoutHeaderContext } from '@peoplefund/components/_app/GlobalLayoutHeaderProvider';
import PageUrls from '../../../constants/page-urls';
const PolicyList: {
  title: string;
  termId: string;
}[] = [{
  title: '개인정보처리방침',
  termId: 'AG0040'
}, {
  title: '고객권리안내문',
  termId: 'AG0058'
}, {
  title: '온라인연계투자약관',
  termId: 'AG0054'
}, {
  title: '온라인연계대출약관',
  termId: 'AG0057'
}, {
  title: '전자금융거래기본약관',
  termId: 'AG0009'
}];
const Footer = () => {
  const {
    hasFooter,
    setHasFooter
  } = useContext(GlobalLayoutHeaderContext);
  useEffect(() => {
    setHasFooter(true);
    return () => {
      /* cleanup  */
      setHasFooter(false);
    };
  }, [setHasFooter]);
  if (!hasFooter) {
    return <></>;
  }
  return <Styled.FooterOuterWrapper data-sentry-element="unknown" data-sentry-component="Footer" data-sentry-source-file="index.tsx">
			<Styled.FooterInnerWrapper data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				<Styled.FooterHeader data-sentry-element="unknown" data-sentry-source-file="index.tsx">
					<Styled.Link rel="noreferrer" target="_blank" href={PageUrls.HOME} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
						<Styled.Logo alt="로고" className="logo" src={`${process.env.NEXT_PUBLIC_STATIC_URL}/assets/img-pflogo-white@2x.png`} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
					</Styled.Link>
					<Styled.MenuWrapper className="pf-menu" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
						<Styled.MenuItem rel="noreferrer" target="_blank" href={PageUrls.pfct.HOME} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							회사소개
						</Styled.MenuItem>
						<Styled.MenuItem rel="noreferrer" target="_blank" href={PageUrls.pfct.NEWS_ROOM} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							뉴스룸
						</Styled.MenuItem>
						<Styled.MenuItem rel="noreferrer" target="_blank" href={PageUrls.pfct.BLOG} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							블로그
						</Styled.MenuItem>
						<Styled.MenuItem rel="noreferrer" target="_blank" href={PageUrls.pfct.RECRUIT} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							채용
						</Styled.MenuItem>
					</Styled.MenuWrapper>
				</Styled.FooterHeader>
				<Styled.FooterBody data-sentry-element="unknown" data-sentry-source-file="index.tsx">
					<Styled.ContentsWrapper data-sentry-element="unknown" data-sentry-source-file="index.tsx">
						<Styled.HighlightText data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							고객센터 {CsPhoneNumber} (오전 9시 - 오후6시)
							<Styled.BrInMobile data-sentry-element="unknown" data-sentry-source-file="index.tsx" /> | E-mail {PfCsEmailAddress}
						</Styled.HighlightText>
						<Styled.DescriptionText data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							폭언, 성희롱 등 업무방해 행위는 형법 및 정보통신망법 등에 의해 처벌받을 수 있습니다. <br />
							[’18.10.18 산업안전보건법 고객 응대 근로자 보호 조치 시행]
						</Styled.DescriptionText>
					</Styled.ContentsWrapper>
					<Styled.ContentsWrapper data-sentry-element="unknown" data-sentry-source-file="index.tsx">
						<Styled.HighlightText data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							피에프씨테크놀로지스(주)
							<Styled.BrInMobile data-sentry-element="unknown" data-sentry-source-file="index.tsx" /> | 대표: 이수환
						</Styled.HighlightText>
						<Styled.DescriptionText data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							사업자등록번호: 668-88-00027 | 온라인투자연계금융업 등록번호 : 2024-22 | 통신판매업신고 :
							2022-서울서초-0788 | 주소 : 서울특별시 서초구 서초대로50길 35, 3~7층 (서초동, 근정빌딩)
						</Styled.DescriptionText>
					</Styled.ContentsWrapper>
					<Styled.ContentsWrapper data-sentry-element="unknown" data-sentry-source-file="index.tsx">
						<Styled.HighlightText data-sentry-element="unknown" data-sentry-source-file="index.tsx">예치기관</Styled.HighlightText>
						<Styled.DescriptionText data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							주식회사 전북은행 | 사업자등록번호: 418-81-07090 | 전화번호: 1588-4477 | 주소: 전주시 덕진구 백제대로 566
							(금암동)
						</Styled.DescriptionText>
					</Styled.ContentsWrapper>
					<Styled.CopyrightText data-sentry-element="unknown" data-sentry-source-file="index.tsx">© PFC Technologies All rights reserved.</Styled.CopyrightText>
				</Styled.FooterBody>
				<Styled.FooterLinks data-sentry-element="unknown" data-sentry-source-file="index.tsx">
					{PolicyList.map(({
          title,
          termId
        }) => <Styled.Link key={termId} rel="noreferrer" target="_blank" href={`https://api.peoplefund.co.kr/terms/v1/${termId}/resource/ko`}>
							{title}
						</Styled.Link>)}
				</Styled.FooterLinks>
			</Styled.FooterInnerWrapper>
		</Styled.FooterOuterWrapper>;
};
export default Footer;