import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GNB_HEIGHT } from '@peoplefund/components/common/Gnb/index.styled';
import { Larger, basicColors, zIndex } from '@pfct/purple';
export const hiddenScrollbar = css`
	&::-webkit-scrollbar {
		display: none;
	}
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE 10+ */
`;
export const Wrapper = styled.section`
	position: relative;
	max-width: 640px;
	height: 100%;

	margin: 0 auto;

	/* overflow: scroll; */

	${Larger(css`
		height: 550px;
	`)}
	${Larger(hiddenScrollbar)}
`;
export const ListWrapper = styled.div<{
  needGridSetting?: boolean;
}>`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 20px 0;

	${({
  needGridSetting
}) => needGridSetting ? css`
					grid-template-columns: repeat(1, 1fr);
					gap: 24px;
			  ` : `${Larger(css`
					grid-template-columns: repeat(3, 1fr);
					gap: 24px;
			  `)}`}
`;
export const ContentWrapper = styled.section`
	padding: 0 20px;
	min-height: 400px;
	overflow-y: scroll;
	background-color: ${basicColors.white};

	${Larger(hiddenScrollbar)}
`;
export const FloatingButtonWrapper = styled.div`
	position: fixed;
	bottom: 0;

	padding: 50px 20px 20px;
	width: 100%;

	z-index: ${zIndex.gnb};
	border: none;
	background: linear-gradient(180deg, transparent, white);

	${Larger(css`
		position: initial;
		bottom: initial;
		padding: 0 20px 20px;
		background: initial;
		background-color: transparent;
	`)}
`;
export const ScrollMissionSection = styled.section`
	position: sticky;
	top: ${GNB_HEIGHT.mobile}px;
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: ${basicColors.gray25};

	padding: 7.5px 20px;
	z-index: ${zIndex.gnb + 1};
`;