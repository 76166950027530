import styled from '@emotion/styled';
export const ToolTipWithQuestionMarkIcon = styled.div`
	margin-left: 5px;

	display: flex;
	align-items: center;

	svg {
		display: block;
	}
`;