import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CsPhoneNumber } from '@peoplefund/constants/company';
import PageUrls from '@peoplefund/constants/page-urls';
import { Headline3, Headline6, Larger, Spacing, basicColors, useLayoutIsMobile } from '@pfct/purple';
import { useRouter } from 'next/router';
import React from 'react';
const Styled = {
  Wrapper: styled.section`
		display: flex;
		background: #212121;
		padding: 0 20px;

		${Larger(css`
			padding: 0;
			justify-content: center;
		`)}
	`,
  ContentWrapper: styled.section`
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 48px 0;
		width: 100%;

		${Larger(css`
			width: 1080px;
			padding: 50px 0;
		`)}
	`,
  ButtonSection: styled.section`
		display: flex;
		justify-content: space-between;
		gap: 13.26px;
	`,
  Button: styled.button`
		display: flex;
		padding: 17px 32.24px;
		justify-content: center;
		align-items: center;
		gap: 12.25px;
		border-radius: 30px;
		border: 1px solid rgba(255, 255, 255, 0.2);
		background: rgba(0, 0, 0, 0.2);

		> img {
			width: 15px;
			height: 15px;
		}
	`,
  MobileButton: styled.button`
		display: flex;
		justify-content: center;
		align-items: center;
		width: 42px;
		height: 42px;
		border-radius: 42px;
		border: 1px solid rgba(255, 255, 255, 0.2);
		background-color: transparent;

		> img {
			width: 15px;
			height: 15px;
		}
	`
};
type ButtonList = {
  imageUrl: string;
  title: string;
  mobileTitle: string;
  onClick: () => void;
};
function FAQBanner() {
  const router = useRouter();
  const ButtonList: ButtonList[] = [{
    imageUrl: 'https://static.peoplefund.co.kr/assets/img/index/icon_call.png',
    title: CsPhoneNumber,
    mobileTitle: '전화',
    onClick: () => {
      document.location.href = `tel:${CsPhoneNumber}`;
    }
  }, {
    imageUrl: 'https://static.peoplefund.co.kr/assets/img/index/icon_faq.png',
    title: 'FAQ',
    mobileTitle: 'FAQ',
    onClick: () => {
      router.push(PageUrls.company.FAQ);
    }
  }];
  return <Styled.Wrapper data-sentry-element="unknown" data-sentry-component="FAQBanner" data-sentry-source-file="FAQBanner.tsx">
			<Styled.ContentWrapper data-sentry-element="unknown" data-sentry-source-file="FAQBanner.tsx">
				<Headline3 color={basicColors.white} data-sentry-element="Headline3" data-sentry-source-file="FAQBanner.tsx">고민하지 말고 물어보세요</Headline3>
				<Headline3 color={basicColors.white} data-sentry-element="Headline3" data-sentry-source-file="FAQBanner.tsx">빠르고 친절하게 설명해 드릴게요</Headline3>
				<Spacing spaceNumber={40} data-sentry-element="Spacing" data-sentry-source-file="FAQBanner.tsx" />
				<FAQButtonList list={ButtonList} data-sentry-element="FAQButtonList" data-sentry-source-file="FAQBanner.tsx" />
			</Styled.ContentWrapper>
		</Styled.Wrapper>;
}
export default FAQBanner;
function FAQButtonList({
  list
}: {
  list: ButtonList[];
}) {
  const isMobile = useLayoutIsMobile();
  if (isMobile) {
    return <Styled.ButtonSection>
				{list.map(item => <div key={item.imageUrl}>
						<Styled.MobileButton onClick={item.onClick}>
							<img src={item.imageUrl} />
						</Styled.MobileButton>
						<Spacing spaceNumber={10} />
						<Headline6 textAlign="center" color={basicColors.white}>
							{item.mobileTitle}
						</Headline6>
					</div>)}
			</Styled.ButtonSection>;
  }
  return <Styled.ButtonSection data-sentry-element="unknown" data-sentry-component="FAQButtonList" data-sentry-source-file="FAQBanner.tsx">
			{list.map(item => <Styled.Button onClick={item.onClick} key={item.imageUrl}>
					<img src={item.imageUrl} />
					<Headline6 color={basicColors.white}>{item.title}</Headline6>
				</Styled.Button>)}
		</Styled.ButtonSection>;
}