import React, { useEffect, useRef } from 'react';
import { useLayoutIsMobile } from '@pfct/purple';
import Slider, { Settings } from 'react-slick';
import * as Styled from './index.styled';
import { getNoticeBackgroundImage, ItemSliderListProps, OLD_FILE_ADDRESS } from './SliderList';
const settings: Settings = {
  infinite: true,
  speed: 1000,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
};
export function BackgroundList({
  investData,
  currItemIndex
}: ItemSliderListProps) {
  const sliderRef = useRef<Slider | null>(null);
  const isMobile = useLayoutIsMobile();
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currItemIndex);
    }
  }, [currItemIndex]);
  if (!investData.length) {
    return <></>;
  }
  return <Styled.StyledSlide {...settings} ref={sliderRef} data-sentry-element="unknown" data-sentry-component="BackgroundList" data-sentry-source-file="BackgroundList.tsx">
			{investData.map((item, i) => {
      const isInvestProduct = item.data_type === 'INVEST';
      const assetUri = item.uri;
      const dealIndex = item.index % 5 === 0 ? 5 : item.index % 5;
      const backgroundImage = item.data_type === 'INVEST' ? `${OLD_FILE_ADDRESS}/files/showcase/${item.first_uri.match(/^ml/) ? `mortgage/${isMobile ? 'bg_top_m' : 'bg_top'}${dealIndex}` : assetUri + '/bg_top_m'}.png` : getNoticeBackgroundImage(isMobile);
      if (isInvestProduct && !item.uri) {
        return <React.Fragment key={i} />;
      }
      return <Styled.BackgroundListItem backgroundImage={backgroundImage} isSelectable={i === currItemIndex} key={i} data-index={i} data-uri={item.uri}>
						<img width={'100%'} height={'494px'} />
					</Styled.BackgroundListItem>;
    })}
		</Styled.StyledSlide>;
}