import dayjs from 'dayjs';
import { InvestDataItem } from './SliderList';

type BannerData = CommonBannerData | CustomBannerData;

interface CommonBannerData {
	data_type: 'INVEST' | 'NOTICE';
	bg_top_mobile_url: string;
	bg_top_url: string;
	title: string;
	sub_title: string;
	seo_description: string;
	state: string;
	uri: string;
	first_uri: string;
	loan_application_term: number;
	interest_rate: number;
	loan_application_amount: number;
	remain_amount: number;
	index: number;
	sub_index: number;
	loan_ordering: string;
	loan_id_text: string;
	loan_application_status: string;
	loan_type: string;
	detailed_loan_type: string;
	sub_type?: string;
	corp_is_invested: boolean;
	percent: number;
	percentOrigin: number;
	investment_count: number;
	investor_amount: number;
	fund_start_datetime: Date;
	fund_waiting_datetime: Date;
	tags: string[];
	notices: string[];
	product_page_url: string;
	type?: string;
	id?: number;
	bg?: string;
	bg_m?: string;
	category?: string;
	category_color?: string;
	categoryCss?: string;
	title_color?: string;
	desc_color?: string;
	button_text?: string;
	button_color?: string;
	button_text_color?: string;
	link_url?: string;
	link_target?: string;
}

interface CustomBannerData {
	data_type: 'INVEST' | 'NOTICE';
	title: string;
	title_color: string;
	bg: string;
	bg_m: string;
	link_url: string;
	link_target: string;
	type: string;
}

interface SliderListItemServerResponse {
	bg_top_mobile_url: string;
	bg_top_url: string;
	title: string;
	capital_preservation_insurance: boolean;
	close_datetime: string | null;
	corp_is_invested: boolean;
	detailed_loan_type: string;
	etc_data_01: string | null;
	first_uri: string;
	fund_end_datetime: string | null;
	fund_pre_start_datetime: string;
	fund_start_datetime: string;
	fund_waiting_datetime: string;
	index: number;
	institute_is_invested: boolean;
	insurances: any[]; // 이 필드가 배열로 제공되어서 any[]로 설정
	interest_rate: number;
	investment_count: number;
	investment_platform: string;
	investor_amount: number;
	is_tranche_r: boolean;
	is_virtual_loan: boolean;
	issuer_type: string;
	letters_of_intent: any[]; // 이 필드도 배열로 제공되어 any[]로 설정
	loan_application_amount: number;
	loan_application_id: number;
	loan_application_status: string;
	loan_application_term: number;
	loan_id_text: string;
	loan_ordering: string;
	loan_section: string;
	loan_title: string;
	loan_type: string;
	ltv_ratio: number;
	npl_letter_of_intent: boolean;
	on_listing: boolean;
	only_investor: boolean;
	platform_commission: number;
	product_id: number;
	product_page_url: string;
	remain_amount: number;
	repayment_type: string;
	residence_status: boolean;
	seo_description: string;
	seo_url: string;
	sub_index: number;
	sub_title: string;
	tags: any[]; // 빈 배열로 제공되었으므로 any[]로 설정
	thumbnail_url: string;
	total_loan_amount: number;
	total_tax_rate: number;
	uri: string;
}

export interface SliderListServerResponse {
	results: SliderListItemServerResponse[];
}

export const getBannerData = (data: SliderListItemServerResponse[] | null): InvestDataItem[] => {
	if (!data) {
		return [];
	}

	const returnArray: InvestDataItem[] = [];

	data.forEach((obj) => {
		if (obj.on_listing) {
			const percent = (obj.investor_amount / obj.loan_application_amount) * 100;
			let state = '';
			let stateMsg = '';

			if (percent < 15) {
				state = 'new';
				stateMsg = 'NEW';
			} else if (percent < 75) {
				state = 'hot';
				stateMsg = 'HOT';
			} else if (percent < 99) {
				state = 'burn';
				stateMsg = '마감임박';
			} else {
				state = 'closed';
				stateMsg = '모집완료';
			}

			const fundStartDate = dayjs(obj.fund_start_datetime).toDate();
			const fundWaitingDate = dayjs(obj.fund_waiting_datetime).toDate();

			const bannerItem: BannerData = {
				data_type: 'INVEST',
				bg_top_mobile_url: obj.bg_top_mobile_url,
				bg_top_url: obj.bg_top_url,
				title: obj.title,
				sub_title: obj.sub_title,
				seo_description: obj.seo_description,
				state: state,
				uri: obj.uri,
				first_uri: obj.first_uri,
				loan_application_term: obj.loan_application_term,
				interest_rate: obj.interest_rate,
				loan_application_amount: obj.loan_application_amount,
				remain_amount: obj.remain_amount,
				index: obj.index,
				sub_index: obj.sub_index,
				loan_ordering: obj.loan_ordering,
				loan_id_text: obj.loan_id_text,
				loan_application_status: obj.loan_application_status,
				loan_type: obj.loan_type,
				detailed_loan_type: obj.detailed_loan_type,
				corp_is_invested: obj.corp_is_invested,
				percent: percent,
				percentOrigin: percent,
				investment_count: obj.investment_count,
				investor_amount: obj.investor_amount,
				fund_start_datetime: fundStartDate,
				fund_waiting_datetime: fundWaitingDate,
				tags: [stateMsg],
				notices: [],
				product_page_url: obj.product_page_url,
			};

			if (obj.corp_is_invested) {
				bannerItem.notices.push('#법인참여');
			}
			bannerItem.notices.push(`#${obj.detailed_loan_type}`);
			if (obj.detailed_loan_type === '아파트담보') {
				if (obj.capital_preservation_insurance) {
					bannerItem.notices.push('#보험가입상품');
				} else {
					bannerItem.notices.push('#연체시 매각가능');
				}
			}

			returnArray.push(bannerItem);
		}
	});

	returnArray.sort((a, b) => b.percent - a.percent);

	return returnArray;
};
