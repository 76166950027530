import React from 'react';
import ShowOffAndBanner from './@components/ShowOffAndBanner';
import CharmingPoint from './@components/CharmingPoint';
import FAQBanner from './@components/FAQBanner';
import Friends from './@components/Friends';
import VentureCapital from './@components/VentureCapital';
import * as Styled from './index.styled';
import Slider from './@components/Slider';
function Main() {
  return <Styled.Wrapper data-sentry-element="unknown" data-sentry-component="Main" data-sentry-source-file="index.tsx">
			<Slider data-sentry-element="Slider" data-sentry-source-file="index.tsx" />
			<ShowOffAndBanner data-sentry-element="ShowOffAndBanner" data-sentry-source-file="index.tsx" />
			<CharmingPoint data-sentry-element="CharmingPoint" data-sentry-source-file="index.tsx" />
			<FAQBanner data-sentry-element="FAQBanner" data-sentry-source-file="index.tsx" />
			<Friends data-sentry-element="Friends" data-sentry-source-file="index.tsx" />
			<VentureCapital data-sentry-element="VentureCapital" data-sentry-source-file="index.tsx" />
		</Styled.Wrapper>;
}
export default Main;