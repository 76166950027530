import { basicColors, Headline1, Headline2, Headline3, Title3, useLayoutIsMobile } from '@pfct/purple';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import PieChart, { CircleDataProps } from './PieChart';
import * as Styled from './index.styled';
import PageUrls from '@peoplefund/constants/page-urls';
export const OLD_FILE_ADDRESS = 'https://static.peoplefund.co.kr'; //process.env.NEXT_PUBLIC_STATIC_URL

// dayjs에서 타임존 플러그인을 추가
dayjs.extend(utc);
dayjs.extend(timezone);
export interface InvestDataItem {
  data_type: 'INVEST' | 'NOTICE';
  uri: string;
  first_uri: string;
  investor_amount: number;
  investment_count: number;
  index: number;
  loan_ordering: string;
  loan_type: string;
  title: string;
  interest_rate: number;
  loan_application_term: number;
  loan_application_amount: number;
  state: string;
  tags: string[];
  notices: string[];
  product_page_url: string;
  fund_start_datetime: Date;
  percent: number;
}
export interface ItemSliderListProps {
  investData: InvestDataItem[];
  currItemIndex: number;
  onNext: () => void;
}
function ItemSliderList({
  investData,
  currItemIndex,
  onNext
}: ItemSliderListProps) {
  const isMobile = useLayoutIsMobile();
  const length = investData.length;
  useEffect(() => {
    let id: NodeJS.Timeout;
    if (length > 0) {
      id = setInterval(onNext, 4000);
    }
    return () => {
      clearInterval(id);
    };
  }, [onNext, length]);
  if (!investData.length) {
    return <></>;
  }
  const TitleComponent = isMobile ? Headline2 : Headline1;
  const SubTitleComponent = isMobile ? Headline3 : Headline3;
  return <>
			{investData.map((item, i) => {
      const isInvestProduct = item.data_type === 'INVEST';
      const showCircle = !isMobile && isInvestProduct;
      const percent = item.investor_amount && item.loan_application_amount ? item.investor_amount / item.loan_application_amount * 100 : 0;
      const circleData: CircleDataProps = {
        fund_start_datetime: item.fund_start_datetime.toDateString(),
        loan_application_status: item.state,
        investment_count: item.investment_count,
        percent
      };
      const subTitleText = isInvestProduct ? `연 ${item.interest_rate}% ${item.loan_application_term}개월` : '더 나은 대출과 투자의 시작';
      const buttonText = isInvestProduct ? '상품 보러가기' : '자세히 보기';
      const href = isInvestProduct ? investData[i].product_page_url ? investData[i].product_page_url : PageUrls.investing.LIST : 'https://blog.pfct.co.kr/service_rebranding/';
      if (isInvestProduct && !item.uri) {
        return <React.Fragment key={i} />;
      }
      return <Styled.Item isSelectable={i === currItemIndex} key={i} data-index={i} data-uri={item.uri}>
						<div className="inner">
							<div className={`info-text-wrapper ${item.state}`}>
								<div>
									<div className="top">
										<div className="tags">
											{item.tags.map((tag, j) => tag === '법인참여' ? <span key={j} className="corporate-label">
														{tag}
													</span> : <span key={j} className="status-label">
														{tag}
													</span>)}
											<Styled.NoticeList>
												{item.notices.map(item => <Title3 color={basicColors.white} key={item}>
														{item}
													</Title3>)}
											</Styled.NoticeList>
										</div>
									</div>
									<TitleComponent color={basicColors.white}>{item.title}</TitleComponent>
									<SubTitleComponent color={basicColors.white}>{subTitleText}</SubTitleComponent>
									<div className="btn-wrap">
										<a href={href} className="btn md btn-primary btn-view-calc-invest" target="_blank" rel="noreferrer">
											{buttonText}
										</a>
									</div>
								</div>
								{showCircle && <PieChart data={circleData} />}
							</div>
						</div>
					</Styled.Item>;
    })}
		</>;
}
export default ItemSliderList;
export function getNoticeBackgroundImage(isMobile: boolean) {
  const fileName = isMobile ? '0a89d901196463585c5475fe9237d8da/Mobile_Banner.png' : 'afdb2f9467dc977d8fa5ece0495bcbed/PC_Banner.png';
  return `https://static.peoplefund.co.kr/files/attachments/banner/20240920/${fileName}`;
}