import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { hiddenScrollbar } from '@peoplefund/components/investing/guide/index.styled';
import { basicColors, headline6, Larger, zIndex } from '@pfct/purple';
import Slider from 'react-slick';

const BASIC_Z_INDEX = zIndex.gnb - 1;

export const Wrapper = styled.section`
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	${hiddenScrollbar};

	width: 100%;
	height: 494px;
	background-color: ${basicColors.black};
`;

export const SlideTrack = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	transition-duration: 400ms;
	overflow: hidden;
	background: transparent;
	z-index: ${BASIC_Z_INDEX};
`;

export const BacroundSlideTrack = styled(SlideTrack)`
	z-index: 0;
`;

export const ArrowBox = {
	Wrapper: styled.div`
		position: absolute;
		display: flex;
		justify-content: flex-end;
		width: 1080px;
		bottom: 0;
		z-index: ${BASIC_Z_INDEX};
	`,
	Icon: styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		width: 37px;
		height: 37px;
		background-color: ${basicColors.white};
		cursor: pointer;
	`,
};

export const PieWrapper = {
	Wrapper: styled.div`
		position: relative;
		right: 100px;
		z-index: ${BASIC_Z_INDEX};
	`,
	Content: styled.div`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 25px;
		z-index: ${BASIC_Z_INDEX};
		> span {
			color: ${basicColors.white};

			&.status .count {
				font-size: 20px;
				font-weight: bold;
			}

			&.rate {
				font-size: 48px;
			}
		}
	`,
};

export const StyledSVG = styled.svg`
	width: 214px;
	height: 214px;
`;

export const CircleBase = styled.circle`
	fill: none;
	stroke: rgba(255, 255, 255, 0.3);
	stroke-width: 5;
`;

export const CircleFill = styled.circle<{ percent: number; circumference: number }>`
	fill: none;
	stroke-width: 5;
	stroke: rgba(255, 255, 255, 1);
	stroke-dasharray: ${({ circumference }) => circumference}px;
	stroke-dashoffset: ${({ percent, circumference }) => circumference * (1 - percent / 100)}px;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
	transition: stroke-dashoffset 1s ease;
`;

export const ItemSliderWrapper = styled.div`
	width: 100%;
	height: 150px;
	position: relative;
	cursor: grab;

	.slick-dots {
		position: absolute;
		top: -35px;
		left: 9px;

		li {
			width: 8px;
			height: 8px;
			margin-right: 4px;
			display: inline-block;

			button {
				width: 8px;
				height: 8px;
				padding: 0;
				display: inline-block;
				vertical-align: top;
				border-radius: 50%;
				border: 1px solid #2e303b;
				z-index: 5;
				text-indent: -9999px;
				background: 0;
			}

			&.slick-active {
				button {
					background: #2e303b;
				}
			}
		}
	}

	.slick-track {
		/* height: 150px; */
		position: relative;
		overflow: hidden;
	}

	#precalculator-slider {
		opacity: 0;
		transition: opacity 0.2s;
		-webkit-transition: opacity 0.2s;

		&.slick-initialized {
			opacity: 1;
		}
	}
`;

export const Item = styled.div<{ isSelectable: boolean }>`
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 0;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;

	opacity: 0;
	transform: translate3d(-30px, 0px, 0px);
	transition: all 1s;
	transition-duration: 1000ms;
	z-index: ${({ isSelectable }) => BASIC_Z_INDEX + (isSelectable ? 1 : 0)};

	${({ isSelectable }) =>
		isSelectable &&
		css`
			opacity: 1;
			transform: translate3d(0px, 0px, 0px);
		`}

	.inner {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		position: relative;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		-webkit-background-size: cover;

		@media (max-width: 767px) {
			width: 100%;
		}

		.info-text-wrapper {
			position: relative;
			display: flex;
			justify-content: space-between;

			width: 100%;
			height: 100%;
			padding: 80px 25px;
			top: 0;
			left: 0;
			box-sizing: border-box;

			${Larger(css`
				width: 1080px;
				padding: 20px;
			`)}

			> div {
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 20px;

				&.closed .top .tags span {
					background: #687386;
				}

				&.burn .top .tags span {
					background: #ea4065;
				}

				&.hot .top .tags span {
					background: #b142fe;
				}

				.top {
					.tags {
						display: flex;
						gap: 10px;
						margin-bottom: 5px;

						span {
							padding: 3px 6px;
							display: inline-block;
							color: #fff;
							text-align: center;
							background: #ffc32d;
							font-size: 15px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
							letter-spacing: -0.2px;

							&.corporate-label {
								background: #171b34;
							}
						}
					}

					.no {
						color: #fff;
						font-size: 12px;
						font-weight: 200;
					}
				}

				h3 {
					font-size: 16px;
					margin: 0;
					font-weight: 200;
					color: #fff;
					line-height: 15px;
					letter-spacing: -0.2px;
				}
			}

			.spec {
				bottom: 15px;
				left: 20px;

				span {
					margin-right: 10px;
					display: inline-block;
					font-size: 12px;
					color: #fff;

					strong {
						position: relative;
						top: 1px;
						font-size: 14px;
					}

					&:last-of-type {
						margin-right: 0;
					}
				}
			}
			.btn-wrap {
				display: flex;
				align-items: center;
				justify-content: center;

				${headline6};

				height: 46px;
				line-height: 46px;
				padding: 0;
				width: 180px;
				margin-right: 10px;
				background: #fff;
				border-radius: 2px;
				border-width: 0;

				a {
					text-decoration: none;
					color: #2e303b;
				}
			}
		}
	}
`;

export const BackgroundList = styled.section`
	display: flex;
	width: fit-content;
	overflow-x: hidden;
`;

export const StyledSlide = styled(Slider)`
	position: relative;
	width: 100%;
	z-index: 0;

	.slick-list {
		position: absolute;
		width: 100%;
		height: 494px;
		overflow: hidden;
	}

	.slick-slide {
		width: 100vw;
	}

	.slick-slider {
		display: flex;
	}

	.slick-track {
		display: flex;
		height: 100%;
	}

	.slick-dots {
		display: none !important;
	}

	.slick-prev {
		position: absolute;
		right: -800px;
		cursor: pointer;
		z-index: 10;
	}

	.slick-next {
		position: absolute;
		left: 810px;
		cursor: pointer;
	}
`;

export const BackgroundListItem = styled.div<{ isSelectable: boolean; backgroundImage: string }>`
	position: relative;
	width: 100%;
	height: 100%;
	opacity: 1;
	transform: initial;
	transition: initial;
	transition-duration: initial;

	> img {
		background: ${(el) => `url(${el.backgroundImage})`};
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
`;

export const NoticeList = styled.ul`
	display: flex;
	margin: 0;
	padding: 0;
	gap: 3px;
`;
