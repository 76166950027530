import React, { useCallback, useEffect, useState } from 'react';
import apiClient from '@peoplefund/utils/axios';
import { ChevronLeftSIcon, ChevronRightSIcon, basicColors } from '@pfct/purple';
import Notice, { convertInvestItemToNoticeItem } from '../Notice';
import * as Styled from './index.styled';
import SliderList, { InvestDataItem } from './SliderList';
import { getBannerData, SliderListServerResponse } from './index.logic';
import { BackgroundList } from './BackgroundList';
const baseUrl = '/pipe/inapi';
function Slider() {
  const [arr, setArr] = useState<InvestDataItem[]>([]);
  const [isSelectedIndex, setIsSelectedIndex] = useState(0);
  const length = arr.length;
  const onClickPrev = useCallback(() => {
    if (!length) {
      return 0;
    }
    setIsSelectedIndex(curr => {
      let res = (curr - 1) % length;
      if (curr === 0) {
        res = length - 1;
      }
      return res;
    });
  }, [length]);
  const onClickNext = useCallback(() => {
    if (!length) {
      return 0;
    }
    setIsSelectedIndex(curr => {
      let res = (curr + 1) % length;
      if (curr === length - 1) {
        res = 0;
      }
      return res;
    });
  }, [length]);
  const onClickNoticeItem = (index: number) => {
    setIsSelectedIndex(index);
  };
  useEffect(() => {
    apiClient.get<SliderListServerResponse>(`${baseUrl}/showcase/v1/loan_applications/`, {
      params: {
        page: 1,
        page_size: 12,
        loan_application_status: '투자모집중'
      }
    }).then(({
      data: {
        results
      }
    }) => {
      setArr([...[{
        data_type: 'NOTICE' as const,
        uri: '',
        first_uri: '',
        investor_amount: 0,
        title: '피플펀드의 새 이름, 크플',
        investment_count: 0,
        index: 0,
        loan_ordering: '0',
        loan_type: '',
        interest_rate: 0,
        loan_application_term: 0,
        loan_application_amount: 0,
        state: '',
        tags: ['NOTICE'],
        notices: [],
        product_page_url: '',
        fund_start_datetime: new Date(),
        percent: 0
      }], ...getBannerData(results)]);
    }).catch(e => {
      console.log(e);
    });
  }, []);
  return <>
			<Styled.Wrapper data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				<Styled.SlideTrack data-sentry-element="unknown" data-sentry-source-file="index.tsx">
					<SliderList investData={arr} currItemIndex={isSelectedIndex} onNext={onClickNext} data-sentry-element="SliderList" data-sentry-source-file="index.tsx" />
				</Styled.SlideTrack>
				<Styled.BacroundSlideTrack data-sentry-element="unknown" data-sentry-source-file="index.tsx">
					<BackgroundList investData={arr} currItemIndex={isSelectedIndex} onNext={onClickNext} data-sentry-element="BackgroundList" data-sentry-source-file="index.tsx" />
				</Styled.BacroundSlideTrack>
				<Styled.ArrowBox.Wrapper data-sentry-element="unknown" data-sentry-source-file="index.tsx">
					<Styled.ArrowBox.Icon onClick={onClickPrev} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
						<ChevronLeftSIcon fillColor={basicColors.gray400} data-sentry-element="ChevronLeftSIcon" data-sentry-source-file="index.tsx" />
					</Styled.ArrowBox.Icon>
					<Styled.ArrowBox.Icon onClick={onClickNext} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
						<ChevronRightSIcon fillColor={basicColors.gray400} data-sentry-element="ChevronRightSIcon" data-sentry-source-file="index.tsx" />
					</Styled.ArrowBox.Icon>
				</Styled.ArrowBox.Wrapper>
			</Styled.Wrapper>
			<Notice list={convertInvestItemToNoticeItem(arr)} currItemIndex={isSelectedIndex} onClick={onClickNoticeItem} data-sentry-element="Notice" data-sentry-source-file="index.tsx" />
		</>;
}
export default Slider;