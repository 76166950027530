import React, { useContext, useEffect } from 'react';
import { GlobalLayoutHeaderContext } from '.';
import useCheckIsTop from '@peoplefund/hooks/useCheckIsTop';
import { BasicProps } from '@peoplefund/components/_app/GlobalLayoutHeaderProvider/GnbNone';
type Props = {
  changeIsTop?: boolean;
  backgroundColorKey?: BasicProps['gnbBackgroundColorKey'];
};
/**
 * pc : gnb, mobile : gnb
 * @param backgroundColorKey : (기본값 : 희색) gnb 배경색
 * @param changeIsTop : (기본값 : false) 상단에 닿았을때만 색깔이 변경되길 원하는지
 * @returns 없습니다. 훅에서 주입됩니다.
 */
const GnbGnb: React.FC<Props> = ({
  backgroundColorKey = 'basic',
  changeIsTop = false
}) => {
  const isTop = useCheckIsTop();
  const {
    setGlobalLayoutHeader
  } = useContext(GlobalLayoutHeaderContext);
  useEffect(() => {
    if (changeIsTop && isTop) {
      setGlobalLayoutHeader<'gnb', 'gnb'>({
        pc: {
          type: 'gnb',
          backgroundColorKey
        },
        mobile: {
          type: 'gnb',
          backgroundColorKey
        }
      });
    } else {
      setGlobalLayoutHeader<'gnb', 'gnb'>({
        pc: {
          type: 'gnb',
          backgroundColorKey: 'basic'
        },
        mobile: {
          type: 'gnb',
          backgroundColorKey: 'basic'
        }
      });
    }
    return () => {
      /* cleanup  */
      setGlobalLayoutHeader<'none', 'none'>({
        pc: {
          type: 'none'
        },
        mobile: {
          type: 'none'
        }
      });
    };
  }, [changeIsTop, isTop, backgroundColorKey, setGlobalLayoutHeader]);
  return null;
};
export default GnbGnb;