import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Headline2, Headline3, Larger, Spacing, useLayoutIsMobile } from '@pfct/purple';
import React from 'react';
const IMAGE_MAIN_PATH = `${process.env.NEXT_PUBLIC_STATIC_URL}/assets/index/partners`;
const PARTNER_IMAGE_URL_LIST = ['logo_baincapital.png', 'logo_goldmansachs.png', 'logo_500.png', 'logo_tbt.png', 'logo_ibx.png', 'logo_kakaopay.png', 'logo_kakao_investment.png', 'logo_shinhan_capital.png', 'logo_shinhan_investment.png', 'logo_d3.png'];
const Styled = {
  Wrapper: styled.section`
		display: flex;
		background: #f8f8f8;
		padding: 0 20px;

		${Larger(css`
			padding: 0;
			justify-content: center;
		`)}
	`,
  ContentWrapper: styled.section`
		padding: 50px 0;
		width: 100%;

		${Larger(css`
			width: 1080px;
			padding: 50px 0;
		`)}
	`,
  ItemList: styled.ul`
		display: grid;
		flex-direction: column;
		grid-template-columns: repeat(2, 1fr);
		gap: 45px;

		margin: 0;
		padding: 0;

		${Larger(css`
			grid-template-columns: repeat(4, 1fr);
			gap: 10px;
		`)}
	`,
  Item: styled.img`
		display: flex;
		width: 150px;
		height: 75px;
		margin: 0;
		padding: 0;

		${Larger(css`
			width: 200px;
			height: 100px;
		`)}
	`
};
function VentureCapital() {
  const isMobile = useLayoutIsMobile();
  const Text = isMobile ? Headline3 : Headline2;
  return <Styled.Wrapper data-sentry-element="unknown" data-sentry-component="VentureCapital" data-sentry-source-file="VentureCapital.tsx">
			<Styled.ContentWrapper data-sentry-element="unknown" data-sentry-source-file="VentureCapital.tsx">
				<div>
					<Text data-sentry-element="Text" data-sentry-source-file="VentureCapital.tsx">크플과 함께하는</Text>
					<Text data-sentry-element="Text" data-sentry-source-file="VentureCapital.tsx">투자사 소개</Text>
				</div>
				<Spacing spaceNumber={50} data-sentry-element="Spacing" data-sentry-source-file="VentureCapital.tsx" />
				<Styled.ItemList data-sentry-element="unknown" data-sentry-source-file="VentureCapital.tsx">
					{PARTNER_IMAGE_URL_LIST.map(url => <Styled.Item key={url} src={`${IMAGE_MAIN_PATH}/${url}`}></Styled.Item>)}
				</Styled.ItemList>
			</Styled.ContentWrapper>
		</Styled.Wrapper>;
}
export default VentureCapital;