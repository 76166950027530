import React from 'react';
import styled from '@emotion/styled';
import { basicColors, Headline6, Larger, Title3, Title4 } from '@pfct/purple';
import { css } from '@emotion/react';
import { hiddenScrollbar } from '@peoplefund/components/investing/guide/index.styled';
import { InvestDataItem } from './Slider/SliderList';
interface NoticeItem {
  title: string;
  subTitle: string;
  description: string;
}
interface Props {
  list: NoticeItem[];
  currItemIndex: number;
  onClick: (index: number) => void;
}
function Notice({
  list,
  currItemIndex,
  onClick
}: Props) {
  return <Styled.Wrapper data-sentry-element="unknown" data-sentry-component="Notice" data-sentry-source-file="Notice.tsx">
			<Styled.Content data-sentry-element="unknown" data-sentry-source-file="Notice.tsx">
				<Styled.List data-sentry-element="unknown" data-sentry-source-file="Notice.tsx">
					{list.map((noticeItem, index) => {
          const selectedColor = currItemIndex === index ? basicColors.blue400 : basicColors.gray900;
          return <Styled.ListItem onClick={() => {
            onClick(index);
          }} key={noticeItem.title}>
								<Styled.ListItemTop>
									<Headline6 color={selectedColor}> {noticeItem.subTitle}</Headline6>
									<Title4 color={selectedColor}>{noticeItem.description}</Title4>
								</Styled.ListItemTop>
								<Styled.ListItemBottom>
									<Title3 color={selectedColor}>{noticeItem.title}</Title3>
								</Styled.ListItemBottom>
							</Styled.ListItem>;
        })}
				</Styled.List>
			</Styled.Content>
		</Styled.Wrapper>;
}
export default Notice;
const Styled = {
  Wrapper: styled.section`
		display: flex;
		justify-content: center;
		width: 100%;
	`,
  Content: styled.section`
		width: 100%;
		overflow-x: scroll;
		${hiddenScrollbar}

		${Larger(css`
			overflow-x: hidden;
			width: 1080px;
		`)}
	`,
  List: styled.ul`
		display: inline-flex;
		align-items: center;
		width: fit-content;
		height: 100%;
		padding: 0;
		margin: 0;
	`,
  ListItem: styled.li`
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 180px;
		padding: 20px 10px;
		margin: 0;
		list-style: none;
		cursor: pointer;
	`,
  ListItemTop: styled.span`
		display: flex;
		align-items: center;
		gap: 8px;
	`,
  ListItemBottom: styled.span`
		display: flex;
		align-items: center;
	`
};
export const convertInvestItemToNoticeItem: (arr: InvestDataItem[]) => NoticeItem[] = arr => {
  return arr.map(item => {
    if (item.data_type === 'NOTICE') {
      return {
        title: item.title,
        subTitle: 'NOTICE',
        description: ''
      };
    }
    const percent = item.investor_amount && item.loan_application_amount ? item.investor_amount / item.loan_application_amount * 100 : 0;
    return {
      title: item.title,
      subTitle: `${percent.toFixed(1)}%`,
      description: getProductStatus(item.state)
    };
  });
};
function getProductStatus(state: string) {
  switch (state) {
    case 'new':
    case 'hot':
      return '모집중';
    case 'burn':
      return '마감임박';
    case 'closed':
      return '모집완료';
    default:
      return '모집중';
  }
}