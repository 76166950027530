import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body2, Headline2, Headline3, Headline4, Larger, Spacing, Title3, useLayoutIsMobile } from '@pfct/purple';
import React from 'react';
const Styled = {
  Wrapper: styled.section`
		display: flex;
		background: #fff;
		padding: 100px 0;

		${Larger(css`
			padding: 0;
			justify-content: center;
		`)}
	`,
  ContentWrapper: styled.section`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		${Larger(css`
			width: 1080px;
			padding: 50px 0;
		`)}
	`,
  List: styled.ul`
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: 0;
		gap: 30px;

		${Larger(css`
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 0;
		`)}
	`,
  ListItem: styled.li`
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
		padding: 0;

		${Larger(css`
			width: 320px;
		`)}
	`
};
function CharmingPoint() {
  const isMobile = useLayoutIsMobile();
  const Text = isMobile ? Headline3 : Headline2;
  return <Styled.Wrapper data-sentry-element="unknown" data-sentry-component="CharmingPoint" data-sentry-source-file="CharmingPoint.tsx">
			<Styled.ContentWrapper data-sentry-element="unknown" data-sentry-source-file="CharmingPoint.tsx">
				<Text data-sentry-element="Text" data-sentry-source-file="CharmingPoint.tsx">폭풍성장 크플</Text>
				<Text data-sentry-element="Text" data-sentry-source-file="CharmingPoint.tsx">세 가지 매력 포인트</Text>
				<Spacing spaceNumber={50} data-sentry-element="Spacing" data-sentry-source-file="CharmingPoint.tsx" />
				<Styled.List data-sentry-element="unknown" data-sentry-source-file="CharmingPoint.tsx">
					{CHARMING_POINT_ITEM_LIST.map(pointItem => <Styled.ListItem key={pointItem.imageUrl}>
							<img src={pointItem.imageUrl} />
							<Headline4>{pointItem.title}</Headline4>
							<Spacing spaceNumber={20} />
							<Title3>{pointItem.subTitle}</Title3>
							{pointItem.comment && <Body2 color="#B0BEC5">{pointItem.comment}</Body2>}
						</Styled.ListItem>)}
				</Styled.List>
			</Styled.ContentWrapper>
		</Styled.Wrapper>;
}
export default CharmingPoint;
const CHARMING_POINT_ITEM_LIST: {
  imageUrl: string;
  title: string;
  subTitle: string;
  comment?: string;
}[] = [{
  imageUrl: 'https://static.peoplefund.co.kr/assets/img/index/keypoints/1.svg',
  title: '손쉬운 투자',
  subTitle: '모바일로 간편하게\n만원부터 부담없이'
}, {
  imageUrl: 'https://static.peoplefund.co.kr/assets/img/index/keypoints/2.svg',
  title: '높은 수익률',
  subTitle: '평균 수익률 10.7%*\n매달 이자를 꼬박꼬박',
  comment: '* 크플 월간공시 기준'
}, {
  imageUrl: 'https://static.peoplefund.co.kr/assets/img/index/keypoints/3.svg',
  title: '다채로운 상품',
  subTitle: '선택의 폭을 넓힌\n다양한 투자상품'
}];