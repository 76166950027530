// import { getGlobalScrollTarget } from '@peoplefund/styles/global';
import { useCallback, useState } from 'react';
import useEventListener from './useEventListener';

const useCheckIsTop = (threshold = 50) => {
	const [isTop, setIsTop] = useState(true);

	const scrollChangeHandler = useCallback(() => {
		const scrollY = window.scrollY; //target?.scrollTop || 0;

		setIsTop(scrollY < threshold);
	}, [threshold]);

	useEventListener('scroll', scrollChangeHandler);

	return isTop;
};

export default useCheckIsTop;
