import React, { useEffect, useState } from 'react';
import * as Styled from './index.styled';
export interface CircleDataProps {
  fund_start_datetime: string;
  loan_application_status: string;
  percent: number;
  investment_count: number;
}
function PieChart({
  data
}: {
  data?: CircleDataProps;
}) {
  const radius = 105;
  const circumference = 2 * Math.PI * radius;
  const [status, setStatus] = useState('모집중');
  useEffect(() => {
    if (!data) {
      return;
    }
    // 상태 확인 및 업데이트
    switch (data.loan_application_status) {
      case '투자모집마감':
      case '실행대기중':
        setStatus('모집완료');
        break;
      default:
        setStatus('모집중');
        break;
    }

    // 타이머 동작
    if (new Date().getTime() < new Date(data.fund_start_datetime).getTime()) {
      const timer = setInterval(() => {
        // const updatedPreDateObj = checkPreStart(data.fund_start_datetime);
        // setPreDateObj(updatedPreDateObj);
      }, 1000);
      return () => clearInterval(timer); // Cleanup timer
    }
  }, [data]);
  if (!data) {
    return <></>;
  }
  const percent = data.percent;
  return <Styled.PieWrapper.Wrapper data-sentry-element="unknown" data-sentry-component="PieChart" data-sentry-source-file="PieChart.tsx">
			<Styled.StyledSVG xmlns="http://www.w3.org/2000/svg" data-sentry-element="unknown" data-sentry-source-file="PieChart.tsx">
				<g data-sentry-element="g" data-sentry-source-file="PieChart.tsx">
					<Styled.CircleBase r={radius} cx="107" cy="107" data-sentry-element="unknown" data-sentry-source-file="PieChart.tsx" />
					<Styled.CircleFill percent={percent} circumference={circumference} r={radius} cx="107" cy="107" data-sentry-element="unknown" data-sentry-source-file="PieChart.tsx" />
				</g>
			</Styled.StyledSVG>
			<Styled.PieWrapper.Content data-sentry-element="unknown" data-sentry-source-file="PieChart.tsx">
				<>
					<span className="status">{status}</span>
					<span className="rate">
						<span className="num">{percent.toFixed(1)}</span>%
					</span>
					<span className="count">
						<span className="num">{data.investment_count}</span>명 참여
					</span>
				</>
			</Styled.PieWrapper.Content>
		</Styled.PieWrapper.Wrapper>;
}
export default PieChart;