import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Larger, basicColors, body2, headline5, headline6, title3 } from '@pfct/purple';
import { PC_LAYOUT_MAX_WIDTH } from '../Gnb/index.styled';
export const Logo = styled.img`
	width: 100px;
	height: 30px;

	margin-bottom: 35px;

	${Larger(css`
		margin-bottom: 0;
	`)}
`;
export const FooterOuterWrapper = styled.div`
	width: 100%;
	background-color: ${basicColors.gray900};
`;
export const FooterInnerWrapper = styled.div`
	width: 100%;

	margin: 0 auto;
	padding: 50px 20px;

	${Larger(css`
		padding: 100px 0;
		max-width: ${PC_LAYOUT_MAX_WIDTH}px;
	`)}
`;
export const FooterHeader = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;

	${Larger(css`
		justify-content: space-between;
		flex-direction: row;

		padding-bottom: 100px;
	`)}
`;
export const MenuWrapper = styled.div`
	display: flex;
	gap: 25px;
	margin-bottom: 40px;

	${Larger(css`
			gap: 70px;
			margin-bottom: 0;
		`)}
`;
export const MenuItem = styled.a`
	cursor: pointer;

	${headline5({
  color: basicColors.white
})};
	text-decoration: none;
`;
export const FooterBody = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
`;
export const ContentsWrapper = styled.div``;
export const BrInMobile = styled.br`
	display: initial;

	${Larger(css`
		display: none;
	`)}
`;
export const HighlightText = styled.div`
	${headline6({
  color: basicColors.white
})}
	margin-bottom: 5px;
`;
export const DescriptionText = styled.div`
	${body2({
  color: basicColors.gray300
})}
`;
export const CopyrightText = styled.div`
	${title3({
  color: basicColors.gray300
})}
`;
export const FooterLinks = styled.div`
	margin-top: 70px;

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(50%, auto));

	${Larger(css`
		display: flex;
		gap: 25px;
		flex-direction: row;
	`)};
`;
export const Link = styled.a`
	cursor: pointer;

	${title3({
  color: basicColors.white
})}
	text-decoration: none;
	width: fit-content;

	margin-bottom: 15px;

	${Larger(css`
		margin-bottom: 0;
	`)}
`;