import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ToolTipWithQuestionMarkIcon from '@peoplefund/components/common/TooltipWithQuestionMarkIcon';
import { DealSummaryAllServerResponse } from '@peoplefund/components/stats/ir/useProductDisclosure';
import { KRWUnit } from '@peoplefund/utils/format.util';
import { basicColors, headline2, Headline4, Larger, title4, Title4, useLayoutIsMobile } from '@pfct/purple';
import React, { useCallback, useEffect, useState } from 'react';
import apiClient from 'utils/axios';
const IMAGE_BASE_URL = `${process.env.NEXT_PUBLIC_STATIC_URL}/assets`;
function ShowOffAndBanner() {
  const isMobile = useLayoutIsMobile();
  const [statisticsData, setStatisticsData] = useState<StatisticsData | null>(null);
  const fetchData취급현황_전체 = useCallback(async () => {
    try {
      const response = await apiClient.get<DealSummaryAllServerResponse>(`${process.env.NEXT_PUBLIC_COMET_URL}/pipe/pelican/gateway/stats/deal/summary/?page_size=2`);
      const rawTableData = response.data.results;
      if (rawTableData.length >= 2) {
        const [currentMonth, prevMonth] = rawTableData;
        const updatedStatisticsData = calculateStatistics(currentMonth, prevMonth, STATISTICS_DATA_LIST);
        setStatisticsData(updatedStatisticsData);
      }
    } catch (e) {
      console.error('Failed to fetch deal summary data:', e);
    }
  }, []);
  useEffect(() => {
    fetchData취급현황_전체();
  }, [fetchData취급현황_전체]);
  const BannerList: {
    bannerImageUrl: string;
    link: string;
  }[] = [{
    bannerImageUrl: `${IMAGE_BASE_URL}/${isMobile ? 'img_banner_thefirst_new_m' : 'img_banner_thefirst_new'}.png`,
    link: 'https://www.cple.co.kr/notice#num=336/?utm_source=invest&utm_medium=website&utm_campaign=engage&utm_content=intermediate_banner_mortgage_invest&utm_term=banner'
  }, {
    bannerImageUrl: `${IMAGE_BASE_URL}/${isMobile ? 'img_banner_cple_m' : 'img_banner_cple'}.png`,
    link: 'https://creditplanet.onelink.me/ecXT/te8q2wzv'
  }];
  return <Styled.Wrapper data-sentry-element="unknown" data-sentry-component="ShowOffAndBanner" data-sentry-source-file="ShowOffAndBanner.tsx">
			<Styled.ContentWrapper data-sentry-element="unknown" data-sentry-source-file="ShowOffAndBanner.tsx">
				<Styled.ShowOffSection data-sentry-element="unknown" data-sentry-source-file="ShowOffAndBanner.tsx">
					<Styled.ShowOffContent data-sentry-element="unknown" data-sentry-source-file="ShowOffAndBanner.tsx">
						<Styled.ShowOffContentList data-sentry-element="unknown" data-sentry-source-file="ShowOffAndBanner.tsx">
							{isMobile ? <MobileStatisticCard items={statisticsData} /> : <StatisticsDisplay data={statisticsData} />}
						</Styled.ShowOffContentList>
					</Styled.ShowOffContent>
				</Styled.ShowOffSection>
				<Styled.BannerSection data-sentry-element="unknown" data-sentry-source-file="ShowOffAndBanner.tsx">
					{BannerList.map(banner => <Styled.BannerItem key={banner.bannerImageUrl}>
							<a target="_blank" href={banner.link} style={{
            backgroundImage: `url(${banner.bannerImageUrl})`
          }} rel="noreferrer" />
						</Styled.BannerItem>)}
				</Styled.BannerSection>
			</Styled.ContentWrapper>
		</Styled.Wrapper>;
}
export default ShowOffAndBanner;
const Styled = {
  Wrapper: styled.section`
		display: flex;
		justify-content: center;
		background: #f8f8f8;
		padding: 35px 0;

		${Larger(css`
			padding: 0;
		`)}
	`,
  ContentWrapper: styled.section`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		${Larger(css`
			width: 1080px;
			padding: 50px 0;
		`)}
	`,
  ShowOffSection: styled.section`
		width: 100%;
		${Larger(css`
			padding: 50px 0 70px;
		`)}
	`,
  ShowOffContent: styled.div`
		display: flex;
		width: 100%;
	`,
  ShowOffContentList: styled.ul`
		display: flex;
		justify-content: center;
		width: 100%;
		margin: 0;
		padding: 0;
	`,
  ShowOffContentListItem: styled.li`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 260px;
		height: 130px;
		margin: 0;
		padding: 25px 0 21.5px;
		list-style: none;

		&:first-of-type {
			background: #2e303b;
		}
	`,
  BannerSection: styled.section`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap: 20px;

		${Larger(css`
			flex-direction: row;
			justify-content: space-between;
		`)}
	`,
  BannerItem: styled.div`
		width: 360px;
		height: 90px;

		${Larger(css`
			width: 530px;
		`)}

		> a {
			display: block;
			width: 100%;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	`
};
interface Tooltip {
  content: string;
  amplitudeMsg: string;
}
interface StatisticItem {
  name: string;
  unit: string;
  tooltip?: Tooltip;
  value?: number; // 새로 추가된 데이터
  state?: number; // 새로 추가된 데이터
}
type StatisticsData = StatisticItem[][];
const calculateStatistics = (currentMonth: DealSummaryAllServerResponse['results'][0], prevMonth: DealSummaryAllServerResponse['results'][0], statisticsData: StatisticsData): StatisticsData => {
  const getDifference = (current: number, previous: number) => current - previous;
  return statisticsData.map(group => group.map(item => {
    let value = 0;
    let state = 0;
    switch (item.name) {
      case '누적대출액':
        value = getFixedAmount(currentMonth.cumulative_handled_amount, KRWUnit.억);
        state = getFixedAmount(getDifference(currentMonth.cumulative_handled_amount, prevMonth.cumulative_handled_amount), KRWUnit.억);
        break;
      case '대출잔액':
        value = getFixedAmount(currentMonth.investment_balance, KRWUnit.억);
        state = getFixedAmount(getDifference(currentMonth.investment_balance, prevMonth.investment_balance), KRWUnit.억);
        break;
      case '상환원금':
        value = getFixedAmount(currentMonth.repaid_principal, KRWUnit.억);
        state = getFixedAmount(getDifference(currentMonth.repaid_principal, prevMonth.repaid_principal), KRWUnit.억);
        break;
      case '상환수익':
        value = getFixedAmount(currentMonth.repaid_profit, KRWUnit.억);
        state = getFixedAmount(getDifference(currentMonth.repaid_profit, prevMonth.repaid_profit), KRWUnit.억);
        break;
      case '평균수익률':
        value = currentMonth.average_profit_rate * 100;
        state = getDifference(currentMonth.average_profit_rate, prevMonth.average_profit_rate);
        break;
      case '누적투자건수':
        value = getFixedAmount(currentMonth.cumulative_investment_count);
        state = getFixedAmount(getDifference(currentMonth.cumulative_investment_count, prevMonth.cumulative_investment_count));
        break;
      case '평균누적투자금액':
        value = getFixedAmount(currentMonth.cumulative_avg_investment_amount, KRWUnit.만);
        state = getFixedAmount(getDifference(currentMonth.cumulative_avg_investment_amount, prevMonth.cumulative_avg_investment_amount), KRWUnit.만);
        break;
      case '재투자율':
        value = getFixedAmount(currentMonth.reinvestment_rate * 100, 1, 2);
        state = getFixedAmount(getDifference(currentMonth.reinvestment_rate * 100, prevMonth.reinvestment_rate * 100), 1, 1);
        break;
      default:
        console.warn(`Unknown item name: ${item.name}`);
        break;
    }
    return {
      ...item,
      value,
      state
    };
  }));
};

/**
 * 주어진 금액을 고정된 소수점 자리수로 반올림합니다.
 * @param amount - 변환할 금액
 * @param unit - 금액 단위를 조정하기 위한 값 (예: 10000, 1 등)
 * @param fixed - 소수점 자리수를 결정합니다 (예: 0, 2 등)
 * @returns 고정된 소수점 자리수로 변환된 금액
 */
function getFixedAmount(amount: number, unit = 1, fixed = 0): number {
  return Number((amount / unit).toFixed(fixed));
}
const STATISTICS_DATA_LIST: StatisticsData = [[{
  name: '누적대출액',
  unit: '억원'
}, {
  name: '대출잔액',
  unit: '억원',
  tooltip: {
    content: '집행된 투자금액 중<br/>상환이 예정된 금액',
    amplitudeMsg: 'Main : Balance Tool Tip'
  }
}, {
  name: '상환원금',
  unit: '억원'
}, {
  name: '상환수익',
  unit: '억원'
}], [{
  name: '평균수익률',
  unit: '%',
  tooltip: {
    content: '모든 채권의 채권별<br/>모집금액이 가중평균된<br/>연환산 수익률',
    amplitudeMsg: 'Main : Rate of Return Tool Tip'
  }
}, {
  name: '누적투자건수',
  unit: '건'
}, {
  name: '평균누적투자금액',
  unit: '만원'
}, {
  name: '재투자율',
  unit: '%',
  tooltip: {
    content: '2회 이상 투자한<br/>투자자의 비율',
    amplitudeMsg: 'Main : Reinvestment Tool Tip'
  }
}]];
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: #fff;
	border-radius: 8px;
`;
const ItemValue = styled.div`
	${headline2};
`;
const ItemUnit = styled.span`
	${headline2};
`;
const Item = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	justify-content: center;
	height: 137.5px;
	text-align: center;
	animation: fadeInOut 5s ease-in-out;

	&:first-of-type {
		background: #2e303b;
		color: ${basicColors.white};

		${ItemValue} {
			color: ${basicColors.white};
		}

		${ItemUnit} {
			color: ${basicColors.white};
		}
	}
`;
const ItemNameWrapper = styled.div`
	display: flex;
	align-items: center;
`;
const ItemName = styled.div`
	font-size: 16px;
	color: ${basicColors.gray200};
`;
const ItemState = styled.div`
	${title4};
	color: ${basicColors.gray900};

	.up {
		color: #ff4d4f;
	}
	.down {
		color: #4d4fff;
	}
`;
const StatisticItem = ({
  name,
  value,
  unit,
  state,
  tooltip
}: StatisticItem) => {
  if (value === undefined || state === undefined) {
    return <></>;
  }
  return <Item data-sentry-element="Item" data-sentry-component="StatisticItem" data-sentry-source-file="ShowOffAndBanner.tsx">
			<ItemNameWrapper data-sentry-element="ItemNameWrapper" data-sentry-source-file="ShowOffAndBanner.tsx">
				<ItemName data-sentry-element="ItemName" data-sentry-source-file="ShowOffAndBanner.tsx">{name}</ItemName>
				{tooltip && <ToolTipWithQuestionMarkIcon content={`${tooltip.content.replace(/<br\s*\/?>/gi, '\n')}`} />}
			</ItemNameWrapper>
			<ItemValue data-sentry-element="ItemValue" data-sentry-source-file="ShowOffAndBanner.tsx">
				{value.toLocaleString()}
				{unit && <ItemUnit>{unit}</ItemUnit>}
			</ItemValue>
			<ItemState data-sentry-element="ItemState" data-sentry-source-file="ShowOffAndBanner.tsx">
				{state > 0 && <span className="up">▲ {state.toLocaleString()}</span>}
				{state === 0 && <span>-</span>}
				{state < 0 && <span className="down">▼ {Math.abs(state).toLocaleString()}</span>}
			</ItemState>
		</Item>;
};
const StatisticsDisplay = ({
  data
}: {
  data: StatisticsData | null;
}) => {
  const [visibleIndex, setVisibleIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleIndex(prevIndex => (prevIndex + 1) % 2);
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  if (!data) {
    return <></>;
  }
  return <Container data-sentry-element="Container" data-sentry-component="StatisticsDisplay" data-sentry-source-file="ShowOffAndBanner.tsx">
			{data[visibleIndex].map((item, index) => <StatisticItem key={index} {...item} />)}
		</Container>;
};
const MobileCard = styled.div`
	height: fit-content;
	background-color: #fff;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	margin-bottom: 16px;
`;
const MobileCardBody = styled.div``;
const MobileCardItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	border-bottom: 1px solid #e0e0e0;
	width: 360px;
	height: 60px;

	&:first-of-type {
		color: ${basicColors.white};
		background-color: ${basicColors.gray900};
	}

	&:last-of-type {
		border-bottom: none;
	}
`;
const MobileItemState = styled.div<{
  isPositive: boolean;
}>`
	font-size: 14px;
	color: ${({
  isPositive
}) => isPositive ? '#ff4d4f' : '#1890ff'};
`;
const MobileStatisticCard = ({
  items
}: {
  items: StatisticsData | null;
}) => {
  const [visibleIndex, setVisibleIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleIndex(prevIndex => (prevIndex + 1) % 2);
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  if (!items) {
    return <></>;
  }
  return <MobileCard data-sentry-element="MobileCard" data-sentry-component="MobileStatisticCard" data-sentry-source-file="ShowOffAndBanner.tsx">
			<MobileCardBody data-sentry-element="MobileCardBody" data-sentry-source-file="ShowOffAndBanner.tsx">
				{items[visibleIndex].map((item, index) => {
        if (item.value === undefined || item.state === undefined) {
          return <React.Fragment key={item.name} />;
        }
        return <MobileCardItem key={index}>
							<ItemNameWrapper>
								<Title4 color={index === 0 ? basicColors.white : undefined}>
									{item.name} ({item.unit})
								</Title4>
								{item.tooltip && <ToolTipWithQuestionMarkIcon content={`${item.tooltip.content.replace(/<br\s*\/?>/gi, '\n')}`} />}
							</ItemNameWrapper>
							<div>
								<Headline4 color={index === 0 ? basicColors.white : undefined}>{item.value.toLocaleString()}</Headline4>
								<MobileItemState isPositive={item.state >= 0}>
									{item.state > 0 && `▲ ${item.state.toLocaleString()}`}
									{item.state < 0 && `▼ ${Math.abs(item.state).toLocaleString()}`}
									{item.state === 0 && '-'}
								</MobileItemState>
							</div>
						</MobileCardItem>;
      })}
			</MobileCardBody>
		</MobileCard>;
};